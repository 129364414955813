import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SiteHeaderButton = ({
  label,
  path,
  variant,
  afterClick,
}: {
  label: string;
  path: string;
  variant?: "text" | "contained" | "outlined";
  afterClick?: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(path);
        afterClick && afterClick();
      }}
      sx={{ color: "white", fontSize: "1rem" }}
      // TODO: variant isn't working for some reason
      variant={variant ? variant : "text"}
    >
      {label}
    </Button>
  );
};

export default SiteHeaderButton;
