import useIsMobile from "@rbt/client/_common/ui/useIsMobile";
import SiteHeaderDesktop from "./SiteHeaderDesktop";
import SiteHeaderMobile from "./SiteHeaderMobile";

function SiteHeader() {
  const mobile = useIsMobile();

  return mobile ? <SiteHeaderMobile /> : <SiteHeaderDesktop />;
}
export default SiteHeader;
