import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppPage from "../_common/AppPage";
import ProjectGrid from "./ProjectGrid";

const ProjectHeader = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      justifyContent="space-between"
      direction="row"
      px={2}
      mt={2.25}
      mb={0}
    >
      <Grid item>
        <Typography variant="h5">My Scripts</Typography>
      </Grid>
      <Grid item justifyContent="right">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/s/new")}
          size="small"
        >
          New Script
        </Button>
      </Grid>
    </Grid>
  );
};

const Projects = () => {
  return (
    <AppPage>
      <Grid item xs={12} lg={8} xl={6}>
        <Card>
          <CardHeader component={ProjectHeader} />
          <CardContent
            sx={{
              paddingTop: 1.5,
              // backgroundColor: "#FAF9F6",
              // backgroundColor: "#F0F0F0",
            }}
          >
            <ProjectGrid />
          </CardContent>
        </Card>
      </Grid>
    </AppPage>
  );
};

export default Projects;
