import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useUploadFile } from "react-firebase-hooks/storage";
import { ref } from "firebase/storage";
import { useState } from "react";
import { storage, userId } from "@rbt/client/_common/firebase";
import AudioProcessing from "./AudioProcessing";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import AppPage from "@rbt/client/_common/AppPage";
import ErrorModal from "@rbt/client/_common/error/ErrorModal";
import { ScriptId } from "@rbt/common/types/script/script";
import transcribeAudio from "../../api/transcribeAudio";
import { VideoShape } from "@rbt/common/types/video";
import createAudioScript from "../../api/createAudioScript";

const ImportAudio = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadFile, uploading, snapshot, error] = useUploadFile();

  const [VideoName, setVideoName] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setSelectedFile = useState<File>()[1];

  const [videoShape, setVideoShape] = useState<VideoShape>("horizontal");

  const [Processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const onInputChange = async (e: any) => {
    const file = e.target.files ? e.target.files[0] : undefined;
    if (!file || !VideoName) return;
    setSelectedFile(file);
    setProcessing(true);

    // upload audio - kept here bc of upload hook
    const scriptId: ScriptId = `s-${nanoid()}`;
    const storageRef = ref(
      storage,
      `u/${userId()}/script/${scriptId}/${file.name}`
    );

    console.time("upload");
    await uploadFile(storageRef, file, { contentType: file.type });
    console.timeEnd("upload");

    await createAudioScript(scriptId, VideoName!, storageRef, videoShape);

    await transcribeAudio({
      scriptId: scriptId,
      storagePath: storageRef.fullPath,
    });

    // Redirect
    navigate(`/s/${scriptId}`);
  };

  const onDemoAudio = async (e: any) => {
    setProcessing(true);
    const scriptId: ScriptId = `s-${nanoid()}`;
    const storageRef = ref(
      storage,
      `u/${userId()}/script/${scriptId}/rbt.demo.mp3`
    );

    await createAudioScript(scriptId, VideoName!, storageRef, videoShape);

    await transcribeAudio({
      scriptId: scriptId,
      storagePath: storageRef.fullPath,
    });

    // Redirect
    navigate(`/s/${scriptId}`);
  };

  const Form = () => (
    <Card>
      <CardHeader title="Create New Script" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="standard"
              label="Name"
              color="secondary"
              fullWidth
              value={VideoName}
              onChange={(e) => setVideoName(e.target.value)}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="video-orientation-label">Video Shape</FormLabel>
              <RadioGroup
                aria-labelledby="video-orientation-label"
                defaultValue="horizontal"
                name="video-orientation"
                row
                value={videoShape}
                onChange={(e) => setVideoShape(e.target.value as any)}
              >
                <FormControlLabel
                  value="horizontal"
                  control={<Radio />}
                  label="Horizontal"
                />
                <FormControlLabel
                  value="vertical"
                  control={<Radio />}
                  label="Vertical"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid container item justifyContent="center">
            <Button
              variant="contained"
              component="label"
              color="secondary"
              size="medium"
              disabled={!VideoName}
            >
              Select MP3 File & Save
              <input
                hidden
                accept=".mp3"
                multiple
                type="file"
                onChange={onInputChange}
              />
            </Button>
            &nbsp; &nbsp;or &nbsp;&nbsp;
            <Button
              variant="contained"
              component="label"
              color="secondary"
              size="medium"
              disabled={!VideoName}
              onClick={onDemoAudio}
            >
              Use Demo Audio
            </Button>
          </Grid>
          {/* <Grid item xs={12}>
            <Alert severity="info">
              Audio files for 1 - 5 minutes work best at the moment. Expect
              large audio file support soon.
            </Alert>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );

  // TODO: move error modal to top of app
  return (
    <>
      <ErrorModal
        open={error !== undefined}
        errorMessage={error?.message ?? ""}
      />

      <AppPage>
        <Grid item lg={8} xl={6}>
          {Processing ? <AudioProcessing /> : <Form />}
        </Grid>
      </AppPage>
    </>
  );
};

export default ImportAudio;
