import { createTheme, ThemeOptions } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#b455dd",
      // main: "#01dfb7",
    },
    secondary: {
      main: "#2A282A",
      // main: "#4f03ce",
    },
    // je: doesn't seem to be a built-in style
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    error: {
      main: "#FBDADC",
    },
    warning: {
      main: "#FBF4DA", // corn silk
      // backgroundColor: "FDF9ED",
      // backgroundColor: "#FDFDED",
      // backgroundColor: "#FFF8EB",
      // backgroundColor: "#FFEFD3",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 13,
  },
};

const theme = createTheme(themeOptions);

export default theme;
