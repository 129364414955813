import YouTubePlaylist from "@codesweetly/react-youtube-playlist";
import ContentPage from "./_common/ContentPage";

const ExamplesPage = () => {
  return (
    <ContentPage title="Examples">
      <YouTubePlaylist
        apiKey="AIzaSyDwHiX2rgTNyFgsiPiBUdWTrv5cgeETRZM"
        playlistId="PLxl6xFx3G59UNMoI9dloUbGJnvarCtEc2"
        uniqueName="Examples"
      />
    </ContentPage>
  );
};

export default ExamplesPage;
