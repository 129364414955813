import { lazy } from "react";
import LoginScreen from "./www/auth/LoginPage";
import HomePage from "./www/home/HomePage";
import Projects from "./project/Projects";
import ImportAudio from "./script/import/audio/ImportAudio";
import AboutPage from "./www/AboutPage";
import ContactPage from "./www/ContactPage";
import FeaturesPage from "./www/FeaturesPage";
import PricingPage from "./www/subscribe/PricingPage";
import ExamplesPage from "./www/ExamplesPage";
import CreateAccountPage from "./www/auth/CreateAccountPage";
import SubscribeCompletePage from "./www/subscribe/SubscribeCompletePage";

type layout = "site" | "app";

type routeConfig = {
  path: string;
  section: layout;
  public?: boolean;
  element: JSX.Element;
};

const LazyEditor = lazy(() => import("./script/edit/ScriptEditorPage"));

export const accountNewPath = "/account/new";
export const loginPath = "/login";
export const pricingPath = "/pricing";
export const subscribePath = "/account/subscribe";
export const subscribedPath = "/account/subscribed";

export const routes: routeConfig[] = [
  // Site
  { path: "/", section: "site", public: true, element: <HomePage /> },
  {
    path: accountNewPath,
    section: "site",
    public: true,
    element: <CreateAccountPage />,
  },
  {
    path: subscribedPath,
    section: "site",
    public: true,
    element: <SubscribeCompletePage />,
  },
  {
    path: "/examples",
    section: "site",
    public: true,
    element: <ExamplesPage />,
  },

  {
    path: "/features",
    section: "site",
    public: true,
    element: <FeaturesPage />,
  },
  {
    path: pricingPath,
    section: "site",
    public: true,
    element: <PricingPage />,
  },
  { path: "/about", section: "site", public: true, element: <AboutPage /> },
  { path: "/contact", section: "site", public: true, element: <ContactPage /> },

  { path: loginPath, section: "site", public: true, element: <LoginScreen /> },
  // App
  { path: "/p", section: "app", element: <Projects /> },
  { path: "/s/new", section: "app", element: <ImportAudio /> },
  { path: "/s/:id", section: "app", element: <LazyEditor /> },
];
