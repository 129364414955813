import ContentPage from "./_common/ContentPage";

const AboutPage = () => {
  return (
    <ContentPage title="Origin Story">
      Reboot TV is a platform for creating illustrated videos from scripts.
    </ContentPage>
  );
};

export default AboutPage;
