import { ThemeProvider } from "@mui/system";
import AppHeader from "./_common/header/AppHeader";
import theme from "./theme";
import { Container, CssBaseline } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import { RequireAuth } from "./_common/auth/RequireAuth";
import HeaderSite from "./www/_common/header/SiteHeader";
import { routes } from "./Routes";
import { Suspense } from "react";

const App = () => {
  const location = useLocation();

  const isSite = routes
    .filter((r) => r.path === location.pathname)
    .map((r) => r.section === "site")
    .includes(true);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        {isSite ? <HeaderSite /> : <AppHeader />}
      </Container>
      <Suspense>
        <Routes>
          {routes.map((r) => (
            <Route
              key={r.path}
              path={r.path}
              element={
                r.public !== true ? (
                  <RequireAuth>{r.element}</RequireAuth>
                ) : (
                  r.element
                )
              }
            />
          ))}
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
