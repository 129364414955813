import ContentPage from "./_common/ContentPage";

const ContactPage = () => {
  return (
    <ContentPage title="Contact Us">
      <iframe
        title="Contact Form"
        src="https://plugins.crisp.chat/urn:crisp.im:contact-form:0/contact/e2ae0f20-adb3-4489-9fb8-bfb72c7a9c3c"
        referrerPolicy="origin"
        sandbox="allow-forms allow-popups allow-scripts allow-same-origin"
        width="100%"
        height="600px"
        frameBorder="0"
        style={{ color: "white" }}
      ></iframe>
    </ContentPage>
  );
};

export default ContactPage;
