import "firebase/compat/auth";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@rbt/client/_common/firebase";
import useLogout from "./useLogout";

const Logout = () => {
  const onClick = useLogout();

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disableElevation={true}
    >
      Logout
    </Button>
  );
};

const Login = () => {
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => navigate("/login")}
      disableElevation={true}
    >
      Login
    </Button>
  );
};

const LoginButton = () => {
  const [user] = useAuthState(auth);
  return <>{user ? <Logout /> : <Login />} </>;
};

export default LoginButton;
