import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

// Logging
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/react";

// // Firebase
import { auth, db, functions, storage } from "@rbt/client/_common/firebase";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectAuthEmulator } from "firebase/auth";
import { connectStorageEmulator } from "firebase/storage";
import { connectFunctionsEmulator } from "firebase/functions";

const localhost = window.location.hostname === "localhost";
const emulate = localhost;

// Firebase Emulator
if (emulate) {
  console.log("Firebase emulator: connecting ");
  connectAuthEmulator(auth, `http://localhost:9099`);
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);
}

// Sentry error handling setup
if (!localhost) {
  Sentry.init({
    dsn: "https://3eb5f18006e64128bc82460bae8a35f2@o1019226.ingest.sentry.io/4504893411164160",
    integrations: [
      new BrowserTracing(),
      // new Sentry.Replay({})
    ],
    environment: localhost ? "localhost" : "production",
    // Profling -  sampling rate
    // Note: sentry says that profiling (beta) is not avaiable for account right now
    tracesSampleRate: 1.0,
    // // Replay sampling rates
    // replaysSessionSampleRate: localhost ? 1 : 0.1,
    // // If the entire session is not sampled, use the below sample rate to sample
    // // sessions when an error occurs.
    // replaysOnErrorSampleRate: 1.0,
  });
  Sentry.setTag("module", "client-web");
}

const WrappedApp = (
  // Strict mode bc breaks firebase auth ui
  // https://github.com/firebase/firebaseui-web-react/issues/172
  // <React.StrictMode>
  <Sentry.ErrorBoundary>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Sentry.ErrorBoundary>
);
// </React.StrictMode>

const rootElement = document.getElementById("root");

ReactDOM.createRoot(rootElement!).render(WrappedApp);

// React Snap - disabled because:
// - hydration error so it wasn't work anyway
// - snap is not maintained
// - we might switch to NextJS anyway
// https://legacy.reactjs.org/docs/error-decoder.html/?invariant=423
// if (rootElement?.hasChildNodes()) {
//   console.log("hydrating");
//   ReactDOM.hydrateRoot(rootElement, WrappedApp);
// } else {
//   console.log("rendering");
//   ReactDOM.createRoot(rootElement!).render(WrappedApp);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
