import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useState } from "react";

const DeleteCell = ({ name, onDelete }: { name: string; onDelete: any }) => {
  const [Open, setOpen] = useState(false);

  const close = () => setOpen(false);

  return (
    <>
      <Button color="secondary" onClick={() => setOpen(true)}>
        Delete
      </Button>
      <Dialog open={Open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Script</DialogTitle>
        <DialogContent>
          Are you certain you want to delete "{name}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDelete();
              close();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteCell;
