import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { StyledFirebaseAuth } from "react-firebaseui";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { auth } from "@rbt/client/_common/firebase";
import firebase from "firebase/compat/app";

const crisp = window["$crisp" as any] as any;
crisp.push(["safe", true]);

const Authentication = ({ redirect }: { redirect?: () => void }) => {
  const [user] = useAuthState(auth);

  const location = useLocation();
  const navigate = useNavigate();

  // Redirect if already logged in; handles page refreshes
  useEffect(() => {
    // if already logged in and page was refreshed
    if (user && location.state && location.state.path) {
      navigate(location.state.path);
    }
  }, [location.state, navigate, user]);

  const onSignIn = (res: any) => {
    // Sentry user data
    const sentryUser = {
      id: user?.uid,
      username: user?.displayName!,
      email: user?.email!,
    };

    Sentry.setUser(sentryUser);

    // Crisp user
    if (window.location.hostname !== "localhost")
      crisp.push(["set", "user:email", res.user.email]);

    if (redirect) {
      redirect();
    } else {
      // if they were trying to access a page prior, redirect there
      const url =
        location.state && location.state.path ? location.state.path : "/p";

      navigate(url);
    }
    return false;
  };

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: onSignIn,
    },
  };
  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
};

export default Authentication;
