import "firebase/compat/auth";
import Authentication from "../../_common/auth/Authentication";
import ContentPage from "@rbt/client/www/_common/ContentPage";

function LoginPage() {
  return (
    <ContentPage title="Login" sxBox={{ minHeight: "200px", pt: 4 }}>
      <Authentication />
    </ContentPage>
  );
}

export default LoginPage;
