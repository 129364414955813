import { Box, Container, Typography, Fade } from "@mui/material";
import theme from "../../theme";

const Hero = () => {
  return (
    <Box className="bg-dots">
      <Box
        sx={{
          // bgcolor: theme.palette.secondary.main,
          color: "white",
          pt: 6,
          pb: 2.5,
          opacity: 0.97,
        }}
      >
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
            sx={{ color: "white" }}
          >
            <Fade in={true} timeout={1000}>
              <span>Your Story Begins</span>
            </Fade>
            <br />
            <Fade in={true} timeout={4000}>
              <span>
                with&nbsp;
                <span style={{ color: theme.palette.primary.main }}>
                  Reboot TV
                </span>
              </span>
            </Fade>
          </Typography>

          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
            sx={{ color: "white" }}
          >
            Script to screen in minutes, not years.
            <br />
            Human powered. AI-assisted. Full creative control.
            {/* <br />
            Human powered, AI assisted.
            <br />
            Full Creative Control. */}
          </Typography>

          {/* <Stack
            sx={{
              pt: 1,
              pb: 2,
            }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button variant="contained">See How</Button>
          </Stack> */}
        </Container>
      </Box>
    </Box>
  );
};

export default Hero;
