import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import { Trophy as TrophyIcon } from "@styled-icons/fluentui-system-regular/Trophy";
import { Script as ScriptIcon } from "@styled-icons/open-iconic/Script";
import { VideoPersonSparkle as VideoIcon } from "@styled-icons/fluentui-system-filled/VideoPersonSparkle";
import { Discount as DiscountIcon } from "@styled-icons/boxicons-solid/Discount";
import SubscribeButton from "./SubscribeButton";

const IndiePricing = () => {
  return (
    <Card>
      <CardHeader
        title="Indie Creator"
        subheader="Creators with < $100,000 USD / year in revenue."
        sx={{ pb: 0.25 }}
      />
      <CardContent sx={{ pt: 0 }}>
        <Stack rowGap={2}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ScriptIcon size="22" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Unlimited AI-Adapted Audio Scripts"
                secondary="Instantly adapt your audio story into a screenplay. Tweak it. And keep all the rights."
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <TrophyIcon size="30" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='100 "Winner" HD Video Images'
                secondary="Only images you choose are counted. So you can focus on your story, not your quota."
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <DiscountIcon size="30" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="20% Off Additional Images"
                secondary="Need more? Get more for only $0.08 / image"
              />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <VideoIcon size="30" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Unlimited Video Renders"
                secondary="Got one more great idea? Edit and re-render until it feels right."
              />
            </ListItem>
          </List>
          <Divider />
          <Typography variant="h5" display="flex" justifyContent="center">
            only $10 / month
          </Typography>
          <SubscribeButton />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default IndiePricing;
