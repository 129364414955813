import { auth } from "@rbt/client/_common/firebase";
import { useNavigate } from "react-router-dom";

export default function useLogout() {
  const navigate = useNavigate();

  const onClick = () => {
    auth.signOut();
    navigate("/");
  };

  return onClick;
}
