import { Box, Card, CardContent, useTheme } from "@mui/material";
import { useScript } from "usehooks-ts";

const Demo = () => {
  useScript(`https://js.storylane.io/js/v1/storylane.js`, {
    removeOnUnmount: false,
  });

  const theme = useTheme();
  return (
    <Card
      sx={{
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        borderRadius: 4,
      }}
    >
      <CardContent sx={{ padding: 0.66 }}>
        <Box>
          <div>
            <script src="https://js.storylane.io/js/v1/storylane.js"></script>
            <div
              className="sl-embed"
              style={{
                position: "relative",
                paddingBottom: "calc(66.77% + 27px)",
                width: "100%",
                height: 0,
                transform: "scale(1)",
              }}
            >
              <iframe
                title="demo"
                className="sl-demo"
                src="https://app.storylane.io/demo/dpsnkdqqemcw"
                name="sl-embed"
                allow="fullscreen; camera; microphone"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
              ></iframe>
            </div>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Demo;
