import { Button } from "@mui/material";
import useCheckout from "./useCheckout";

const SubscribeButton = () => {
  const [paddleOpen, subscribe] = useCheckout();

  return (
    <Button variant="contained" onClick={subscribe} disabled={paddleOpen}>
      Start Your Free 7-Day Trial
    </Button>
  );
};

export default SubscribeButton;
