import Demo from "./home/Demo";
import ContentPage from "./_common/ContentPage";

const FeaturesPage = () => {
  return (
    <ContentPage title="Feature Tour">
      <Demo />
    </ContentPage>
  );
};

export default FeaturesPage;
