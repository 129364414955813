import "firebase/compat/auth";
import Authentication from "../../_common/auth/Authentication";
import ContentPage from "@rbt/client/www/_common/ContentPage";
import useCheckout from "../subscribe/useCheckout";
import { useIdToken } from "react-firebase-hooks/auth";
import { auth } from "@rbt/client/_common/firebase";
import { useEffect, useState } from "react";
import { Stack, CircularProgress } from "@mui/material";

function CreateAccountPage() {
  const subscribe = useCheckout()[1];
  const user = useIdToken(auth)[0];

  const [loading, setLoading] = useState(false);

  // Wait until user exists then open Paddle
  useEffect(() => {
    if (!user?.uid) return;
    subscribe();
  }, [subscribe, user]);

  return (
    <ContentPage
      title={loading ? "Creating Account..." : "Let's Create Your Account..."}
      sxBox={{ minHeight: "200px", pt: 4 }}
    >
      {!loading && (
        <Authentication
          redirect={() => {
            setLoading(true);
          }}
        />
      )}
      {loading && (
        <Stack
          alignItems="center"
          spacing={1.75}
          sx={{ height: "100%", pt: 4 }}
          alignSelf="center"
        >
          <CircularProgress
            color="primary"
            size="75px"
            sx={{ animationDuration: "10s" }}
          />
        </Stack>
      )}
    </ContentPage>
  );
}

export default CreateAccountPage;
