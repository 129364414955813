import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Hero from "./Hero";
import Demo from "./Demo";
import Footer from "../../_common/footer/Footer";

export default function HomePage() {
  return (
    <>
      <main>
        <Hero />
        <Box sx={{ backgroundColor: "#332D3F" }}>
          <Container maxWidth="lg">
            <Box
              sx={{
                py: 2,
              }}
            >
              <Demo />
            </Box>
          </Container>
        </Box>
      </main>
      <Footer />
    </>
  );
}
