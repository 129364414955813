import { auth } from "../../_common/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useScript } from "usehooks-ts";
import { accountNewPath } from "@rbt/client/Routes";
import { useIdToken } from "react-firebase-hooks/auth";

export type Navigation = { path: string; options?: any };

export const Paddle = () => window["Paddle" as any] as any;

export default function useCheckout(config?: {
  successUrl?: string;
  cancelUrl?: string;
}): [boolean, () => void] {
  const user = useIdToken(auth)[0];
  const navigate = useNavigate();

  // Load Paddle
  const paddleStatus = useScript("https://cdn.paddle.com/paddle/paddle.js");

  useEffect(() => {
    if (paddleStatus !== "ready") return;

    if (process.env.REACT_APP_PADDLE_SANDBOX !== "false") {
      Paddle().Environment.set("sandbox");
    }
    Paddle().Setup({
      vendor: Number(process.env.REACT_APP_PADDLE_VENDOR_ID as string),
    });
  }, [paddleStatus]);

  const [paddleOpen, setPaddleOpen] = useState(false);

  const subscribe = () => {
    if (!user) {
      navigate(accountNewPath, {
        state: { path: "/pricing?checkout=1" },
      });
      return;
    }

    setPaddleOpen(true);

    (window as any).onPaddleSuccess = function () {
      window.location.href = "/account/subscribed";
    };

    (window as any).onPaddleClose = function () {
      setPaddleOpen(false);
    };

    Paddle().Checkout.open({
      product: process.env.REACT_APP_PADDLE_PRODUCT_ID,
      email: user.email,
      disableLogout: true,
      customData: {
        userId: user.uid,
      },
      closeCallback: "onPaddleClose",
      successCallback: "onPaddleSuccess",
    });
  };

  return [paddleOpen, subscribe];
}
