import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

const AudioProcessing = () => {
  return (
    <Card sx={{ minHeight: 250 }}>
      <CardHeader title="Processing Audio" />
      <CardContent>
        <Stack alignItems="center" spacing={5}>
          <CircularProgress color="secondary" />
          <Typography variant="h6">Transcribing...</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AudioProcessing;
