import { userId, getCreateMeta, db } from "@rbt/client/_common/firebase";
import { AudioScript } from "@rbt/common/types/script/audioScript";
import { ScriptId } from "@rbt/common/types/script/script";
import { VideoShape } from "@rbt/common/types/video";
import { setDoc, doc } from "firebase/firestore";
import { StorageReference } from "firebase/storage";

export default async function createAudioScript(
  scriptId: ScriptId,
  name: string,
  storageRef: StorageReference,
  shape: VideoShape
) {
  // Podvid create
  const transVid: AudioScript = {
    id: scriptId,
    projectId: scriptId,
    type: "audio",
    name: name,
    audioFile: {
      storagePath: storageRef.fullPath,
    },
    ...getCreateMeta(),
    videoShape: shape,
  };

  return await setDoc(doc(db, `u/${userId()}/script`, scriptId), transVid);
}
