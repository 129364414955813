import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Skeleton } from "@mui/material";
import { collection, doc, query, updateDoc, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { db, auth, getDeleteMeta, userId } from "@rbt/client/_common/firebase";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "@rbt/client/_common/util/formatTimestamp";
import DeleteCell from "../_common/datagrid/DeleteCell";

const ProjectGrid = () => {
  const navigate = useNavigate();

  // Data
  const scriptQuery = query(
    collection(db, `u/${userId()}/script`),
    where("deleted", "==", false)
  );

  const scripts = useCollection(scriptQuery)[0];

  const softDelete = async (id: string) => {
    await updateDoc(doc(db, `u/${auth.currentUser?.uid}/script`, id), {
      ...getDeleteMeta(),
    });
  };

  // Layout
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 400,
      valueGetter: (p) => p.row.data().name,
    },
    {
      field: "createdDate",
      headerName: "Created",
      width: 150,
      valueGetter: (p) => formatTimestamp(p.row.data().createdDate),
    },
    // {
    //   field: "deleted",
    //   headerName: "Deleted",
    //   width: 75,
    // },
    {
      field: "actions",
      type: "actions",
      getActions: (p) => [
        <DeleteCell
          name={p.row.data().name}
          onDelete={() => softDelete(p.row.id)}
        />,
      ],
    },
  ];

  // UI
  const onClick = (p: any) => {
    navigate(`/s/${p.id}`);
  };

  const Skel = () => <Skeleton variant="text" animation="wave" height={40} />;

  const GridLoading = () => (
    <>
      <Skel />
      <Skel />
      <Skel />
      <Skel />
    </>
  );

  const GridReady = () => (
    <DataGrid
      getRowId={(row) => row.id}
      rows={scripts!.docs}
      // rows={scripts!.docs}
      columns={columns}
      onRowClick={onClick}
      sx={{ minHeight: 350 }}
    />
  );

  return scripts ? <GridReady /> : <GridLoading />;
};

export default ProjectGrid;
