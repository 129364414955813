import { Button, Stack, Typography } from "@mui/material";
import ContentPage from "../_common/ContentPage";
import { useNavigate } from "react-router-dom";

const SubscribeCompletePage = () => {
  const center = { display: "flex", justifyContent: "center" };

  const navigate = useNavigate();
  return (
    <ContentPage>
      <Typography variant="h4" {...center}>
        Welcome!
      </Typography>
      <Typography {...center} sx={{ py: 3 }}>
        Welcome to the Reboot Community. You're ready to create.
      </Typography>
      <Stack direction="row" spacing={3} justifyContent="center">
        <Button
          variant="contained"
          sx={{ maxWidth: "200px" }}
          onClick={() => navigate("/p")}
        >
          My Projects
        </Button>
        <Button
          variant="contained"
          sx={{ maxWidth: "200px" }}
          onClick={() => navigate("/s/new")}
        >
          Create New Project
        </Button>
      </Stack>
    </ContentPage>
  );
};

export default SubscribeCompletePage;
