import {
  Box,
  AppBar,
  Container,
  Toolbar,
  Grid,
  ButtonGroup,
} from "@mui/material";
import LoginButton from "@rbt/client/_common/auth/LoginButton";
import { Link } from "react-router-dom";
import SiteHeaderButton from "./SiteHeaderButton";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "@rbt/client/_common/firebase";
import AccountMenu from "./AccountMenu";

const SiteHeaderDesktop = () => {
  const [user] = useAuthState(auth);

  return (
    <Box className="bg-film">
      <AppBar position="static" elevation={2} sx={{ opacity: 0.97 }}>
        <Container maxWidth="xl">
          <Toolbar>
            <Grid
              container
              pt={2}
              pb={1.5}
              alignItems={"flex-end"}
              spacing={5}
              justifyContent="space-between"
            >
              <Grid item xs={1}>
                <Link to="/">
                  <img
                    src="/img/logo-wo-antennae-small.png"
                    alt="logo"
                    height="70"
                  />
                </Link>
              </Grid>
              <Grid item>
                <ButtonGroup
                  variant="text"
                  size="medium"
                  disableElevation
                  sx={{ color: "white", textTransform: "lowercase" }}
                >
                  <SiteHeaderButton label="Examples" path="/examples" />
                  {/* <SiteHeaderButton label="Pricing" path="/pricing" /> */}
                  <SiteHeaderButton label="Contact" path="/contact" />
                  {/* temp way to get back to app if logged in */}
                  {user && (
                    <SiteHeaderButton
                      variant="outlined"
                      label="Application"
                      path="/p"
                    />
                  )}
                </ButtonGroup>
              </Grid>
              <Grid item>{user ? <AccountMenu /> : <LoginButton />} </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default SiteHeaderDesktop;
