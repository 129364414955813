import { functions } from "@rbt/client/_common/firebase";
import { TranscribeAudioRequest } from "@rbt/common/types/script/transcript";
import { httpsCallable } from "firebase/functions";

export default async function transcribeAudio(req: TranscribeAudioRequest) {
  // Transcribe
  console.time("transcribe");
  const transcribe = await httpsCallable(functions, "audioImport", {
    timeout: 300000,
  });

  await transcribe(req);
  console.timeEnd("transcribe");
}
