import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Menu as MenuIcon } from "@styled-icons/feather/Menu";
import SiteHeaderButton from "./SiteHeaderButton";
import LoginButton from "@rbt/client/_common/auth/LoginButton";
import { useState } from "react";

const SiteMobileHeader = () => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);

  return (
    <Box className="bg-film" sx={{ width: "100%" }}>
      <Accordion
        sx={{
          backgroundColor: theme.palette.primary.main,
          opacity: 0.97,
        }}
        square={true}
        expanded={open}
        onClick={() => setOpen(!open)}
      >
        <AccordionSummary sx={{ borderRadius: 0 }}>
          <Grid container pt={1}>
            <Grid item xs={1.25} alignSelf="center">
              <MenuIcon size="34" color="white" />
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: 1 }}>
              <Link to="/">
                <img
                  src="/img/logo-wo-antennae-small.png"
                  alt="logo"
                  height="60"
                />
              </Link>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Stack justifyContent="center" justifySelf="center">
            <SiteHeaderButton label="Home" path="/" afterClick={close} />
            <SiteHeaderButton label="Examples" path="/examples" />
            {/* <SiteHeaderButton label="Pricing" path="/pricing" /> */}
            <SiteHeaderButton
              label="Contact"
              path="/contact"
              afterClick={close}
            />
            {/* {portalUrl && (
              <Button
                variant="contained"
                onClick={() => window.location.assign(portalUrl)}
                sx={{ color: "white", fontSize: "1rem" }}
              >
                Subscription
              </Button>
            )} */}
            <LoginButton />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SiteMobileHeader;
