import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import LoginButton from "../auth/LoginButton";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, ButtonGroup } from "@mui/material";
import { auth, userId } from "../firebase";
import AccountMenu from "@rbt/client/www/_common/header/AccountMenu";
import { useAuthState } from "react-firebase-hooks/auth";

function AppHeader() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  return (
    <Box className="bg-film">
      <AppBar
        position="static"
        elevation={10}
        sx={{
          opacity: 0.97,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Grid
              container
              pt={2}
              pb={1}
              alignItems={"flex-end"}
              spacing={5}
              justifyContent="space-between"
            >
              <Grid item xs={1}>
                <Link to="/">
                  <img
                    src="/img/logo-wo-antennae-small.png"
                    alt="logo"
                    height="50"
                  />
                </Link>
              </Grid>
              <Grid item>
                {userId() && (
                  <ButtonGroup
                    variant="contained"
                    color="secondary"
                    disableElevation
                  >
                    <Button onClick={() => navigate("/p")} size="medium">
                      My Scripts
                    </Button>
                    <Button size="medium" onClick={() => navigate("/s/new")}>
                      New Script
                    </Button>
                  </ButtonGroup>
                )}
              </Grid>
              <Grid item>{user ? <AccountMenu /> : <LoginButton />}</Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}
export default AppHeader;
