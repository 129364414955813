import { Grid } from "@mui/material";
import ContentPage from "../_common/ContentPage";
import IndiePricing from "./IndiePricing";

export const Paddle = () => window["Paddle" as any] as any;

const PricingPage = () => {
  return (
    <ContentPage title="Pricing">
      <Grid container columnSpacing={2} justifyContent="center">
        <Grid item xs={12} md={7}>
          <IndiePricing />
        </Grid>
        {/* <Grid item xs={5}>
          <PublisherPricing />
        </Grid> */}
      </Grid>
    </ContentPage>
  );
};

export default PricingPage;
