import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Paper, Stack } from "@mui/material";

const Copyright = () => {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.reboottv.net">
        Conatus Innovation LLC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const FooterLink = ({ text, url }: { text: string; url: string }) => {
  return (
    <Typography variant="body2" align="center">
      <Link color="inherit" href={url} target="_blank">
        {text}
      </Link>{" "}
    </Typography>
  );
};

const Footer = () => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        marginTop: "calc(10% + 60px)",
        width: "100%",
        position: "fixed",
        bottom: 0,
      }}
      component="footer"
      square
    >
      <Box className="bg-film">
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            opacity: 0.97,
          }}
        >
          <Container maxWidth="lg" sx={{ color: "white" }}>
            <Stack
              direction="row"
              spacing={3}
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                display: "flex",
                pt: 1.5,
                mb: 1,
                minHeight: "55px",
                color: "white",
              }}
            >
              <Copyright />
              <FooterLink text="Privacy Policy" url="/privacy.html" />
              <FooterLink text="Terms of Service" url="/tos.html" />
            </Stack>
          </Container>
        </Box>
      </Box>
    </Paper>
  );
};

export default Footer;
