import { Alert, Dialog, Typography, Stack } from "@mui/material";

const ErrorModal = ({
  open,
  errorMessage,
}: {
  open: boolean;
  errorMessage: string;
}) => (
  <Dialog open={open} maxWidth="sm" fullWidth>
    <Alert severity="error">
      <Stack>
        <Typography>Error Occured</Typography>
        <Typography>{errorMessage} </Typography>
      </Stack>
    </Alert>
  </Dialog>
);
export default ErrorModal;
