import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ReactProps } from "./ReactProps";

const AppPage = (props: ReactProps) => (
  <Box>
    <Container maxWidth="xl" sx={{ pb: 2 }}>
      <Grid
        container
        py={3}
        sx={{
          minHeight: "max-content",
        }}
        direction="row"
        justifyContent="center"
        {...props}
      >
        {props.children}
      </Grid>
    </Container>
  </Box>
);

export default AppPage;
