import {
  Box,
  Container,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Footer from "@rbt/client/_common/footer/Footer";

const ContentPage = ({
  title,
  children,
  sxBox,
}: { title?: string; sxBox?: any } & React.PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Stack>
      <Container maxWidth="md">
        <Grid
          container
          my={3}
          spacing={2}
          sx={{
            minHeight: "max-content",
            backgroundColor: theme.palette.secondary.main,
          }}
          direction="row"
          justifyContent="center"
        >
          <Paper
            sx={{
              width: "100%",
              // TODO: hoist to theme
              backgroundColor: "#332D3F",
              color: "white",
              p: 3,
              borderRadius: 2,
            }}
            elevation={2}
          >
            <Stack>
              {title && (
                <Typography variant="h4" sx={{ pb: 0.5 }}>
                  {title}
                </Typography>
              )}
              <Box sx={{ pt: sxBox?.pt ?? 1.75, pb: 1 }} {...sxBox}>
                {children}
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Container>
      <Footer />
    </Stack>
  );
};

export default ContentPage;
